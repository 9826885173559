import React from 'react'
import { useThemeUI } from 'theme-ui'

const Resume = () => {
  const context = useThemeUI()
  const { colorMode } = context
  const isDark = colorMode === `dark`

  return (
    <>
      <img
        src='/resume-page1.svg'
        alt='Resume Page 1'
        style={{
          marginLeft: '-10%',
          width: '120%',
          maxWidth: 1080,
          height: 'auto',
          filter: isDark ? 'invert(1) brightness(0.8)' : 'none'
        }}
      />
      <img
        src='/resume-page2.svg'
        alt='Resume Page 2'
        style={{
          marginLeft: '-10%',
          marginTop: '-20%',
          marginBottom: '-50%',
          width: '120%',
          maxWidth: 1080,
          height: 'auto',
          filter: isDark ? 'invert(1) brightness(0.8)' : 'none'
        }}
      />
    </>
  )
}

export default Resume;
